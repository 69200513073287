<template>
  <div class="div_app_body">
    <n-message-provider>
      <router-view></router-view>
    </n-message-provider>
  </div>
</template>

<script setup>
import { useCounterStore } from '@/stores/counter'
import { storeToRefs } from 'pinia'
import { getWxConfig } from '@/api/wx.js'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const store = useCounterStore()
const { user } = storeToRefs(store)

let appId = import.meta.env.VITE_APPID

useWxSdk()

async function useWxSdk() {
  let url = window.location.href.split('#')[0]
  let res = await getWxConfig({ strUrl: url })
  wx.config({
    debug: false, // 开启调试模式
    appId: appId, // 必填，公众号的唯一标识
    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.data.noncestr, // 必填，生成签名的随机串
    signature: res.data.signature, // 必填，签名
    jsApiList: ['checkJsApi', 'onMenuShareTimeline', 'onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
  })
  wx.ready(() => {
    console.log('微信JS-SDK初始化成功')
  })
  wx.error((res) => {
    console.log('微信JS-SDK初始化失败', res)
  })
  //  if (!user.value && route.name !== 'login') {
  //     router.push({ name: 'login' })
  //   }
}
</script>

<style lang="scss" scoped>
.div_app_body {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}
</style>
