import { createRouter, createWebHistory } from 'vue-router'
import { useCounterStore } from '@/stores/counter'
import { detectDeviceType, isWeixin } from '@/util/index.js'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/wx-login',
      name: 'login',
      meta: {
        title: '登录'
      },
      component: () => import('../views/login/login.vue')
    },
    {
      path: '/bind-phone',
      name: 'bindPhone',
      meta: {
        title: '登录'
      },
      component: () => import('../views/login/bindPhone.vue')
    },
    {
      path: '/community',
      name: 'community',
      children: [
        {
          path: 'file-preview',
          name: 'filePreview',
          component: () => import('../views/community/filePreview.vue'),
          meta: {
            title: '文件预览'
          }
        }
      ]
    }
  ]
})
router.beforeEach((to, from, next) => {
  const store = useCounterStore()
  // PC端或者非移动端微信打开-跳转至网页版项目中
  if (!detectDeviceType() || !isWeixin()) {
    let url = import.meta.env.VITE_APP_URL
    let pushUrl = url + to.fullPath
    window.location.href = pushUrl
    return
  }
  // 首次授权微信登录
  if (to.query.code) {
    let obj = JSON.parse(JSON.stringify(to.query))
    console.log(to.query)
    delete obj.code
    delete obj.state
    store
      .getUserMessage(to.query.code)
      .then(() => {
        next({ path: to.path, query: obj })
      })
      .catch((title) => {
        let searchParams = new URLSearchParams(obj)
        let queryString = searchParams.toString()
        let sourceUrl = window.location.origin + '?' + queryString
        switch (title) {
          case '新用户':
            next({ name: 'bindPhone', query: { source: sourceUrl } })
            break

          default:
            next({ name: 'login', query: { source: sourceUrl } })
            break
        }
      })

    return
  }
  if (store.user) {
    next()
  } else {
    store
      .getUserMessage()
      .then(() => {
        next()
      })
      .catch((title) => {
        let currentUrl = window.location.href
        switch (title) {
          case '登录失效':
            next({ name: 'login', query: { source: currentUrl } })
            break
          default:
            next()
            break
        }
      })
  }
})
export default router
