import axios from "axios";
import { getLocalStor } from "../util/index.js";

const http = axios.create({
  baseURL: "/api", // 替换为你的API基础URL
  timeout: 60000, // 请求超时时间
  headers: {
    device: "web",
    "Cache-Control": "no-cache",
  },
});

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    let userObj = getLocalStor("userMessage") || null;
    if (userObj) {
      config.headers["Authorization"] = userObj.token;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    // 对响应数据做处理，例如只返回data部分
    return response.data;
  },
  (error) => {
    // 响应错误处理
    return Promise.reject(error);
  }
);

const callApi = (method, url, data, listen) => {
  let listObj = null;
  if (listen) {
    listObj = listen;
  }
  return http({
    method: method === "UPLOAD" ? "POST" : method,
    url,
    params: method === "GET" ? data : {},
    data: method === "UPLOAD" || "POST" ? data : {},
    headers:
      method === "UPLOAD"
        ? { "Content-Type": "multipart/form-data" }
        : { "Content-Type": "application/json" },
    onUploadProgress: listObj, //进度事件
  });
};
export const getApi = (url, data) => callApi("GET", url, data);
export const postApi = (url, data) => callApi("POST", url, data);
export const uploadApi = (url, data, listen) =>
  callApi("UPLOAD", url, data, listen);
