import { defineStore } from 'pinia'
import { getLocalStor, setLocalStor } from '@/util/index.js'

import { findByToken, getUserInfo } from '@/api/login.js'

export const useCounterStore = defineStore('counter', {
  // other options like state, getters, actions
  state: () => ({
    user: null,
    unionid: null //用户
  }),
  getters: {}, // other getters
  actions: {
    getUserMessage(code) {
      return new Promise((resolve, reject) => {
        if (code) {
          getUserInfo(code)
            .then((res) => {
              if (res.code == '200') {
                if (res.data.newUser) {
                  this.unionid = res.data.unionid
                  reject('新用户')
                } else {
                  setLocalStor('userMessage', {
                    token: res.data.token
                  })
                  this.user = res.data
                  resolve()
                }
              } else {
                this.user = null
                reject('登录失败')
              }
            })
            .catch(() => {
              this.user = null
              reject('登录失败')
            })
        } else {
          if (localStorage.getItem('userMessage')) {
            let obj = getLocalStor('userMessage')
            findByToken(obj.token)
              .then((res) => {
                if (res.code == '200') {
                  this.user = res.data
                  resolve()
                } else {
                  localStorage.clear()
                  this.user = null
                  reject('登录失效')
                }
              })
              .catch(() => {
                reject('未登录')
              })
          } else {
            this.user = null
            resolve('未登录')
          }
        }
      })
    }
  }
})
