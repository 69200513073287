import { postApi } from '@/util/reqLogin.js'
import axios from 'axios'

/** 微信授权获取用户信息
 *  @param {String} code 微信授权code
 */
export function getUserInfo(code) {
  return postApi(`admin/user/callBack?code=${code}`)
}
// 功能模块 查询当前用户信息-token
export const findByToken = (token) => postApi(`/admin/user/findByToken?token=${token}`)

// 获取短信验证码
export const sendValidateCode = (mobile, num) =>
  postApi(`/admin/validateCode/sendValidateCode?mobile=${mobile}&num=${num}`)

// 短信登录
export function loginByValidateCode(unionid, data) {
  axios({
    baseURL: '/login', // 替换为你的API基础URL
    method: 'post',
    url: `/admin/user/loginByValidateCode?mobile=${data.mobile}&mobileCode=${data.mobileCode}&num=${data.num}`,
    headers: {
      device: 'web',
      unionid
    }
  })
}
