import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import katex from 'katex'

// 获取存储本地数据
export function getLocalStor(name) {
  let localObj = JSON.parse(localStorage.getItem(name))
  return localObj
}
// 修改本地数据，可对比替换某一对象。无需全部填写
export function setLocalStor(name, data) {
  let localObj = JSON.parse(localStorage.getItem(name))
    ? JSON.parse(localStorage.getItem(name))
    : false
  if (localObj && typeof localObj == 'object' && typeof data == 'object') {
    for (const key in data) {
      localObj[key] = data[key]
    }
  } else {
    localObj = data
  }
  localStorage.setItem(name, JSON.stringify(localObj))
}
//将base64转换为文件
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}
// 复制文本到剪贴板
export function copyToClipboard(url) {
  let copyInput = document.createElement('input')
  document.body.appendChild(copyInput)
  copyInput.value = url
  copyInput.select()
  document.execCommand('copy')
  copyInput.remove()
}
// 获取富文本html内容
export const quillGetHTML = (queryDetal) => {
  queryDetal = JSON.parse(queryDetal)
  queryDetal.forEach((value) => {
    if (value && value.insert && value.insert.hollow) {
      if (!value.attributes) {
        value.attributes = {
          isHollow: 'true',
          background: '#fff79b'
        }
      } else {
        value.attributes.isHollow = 'true'
        value.attributes.background = '#fff79b'
      }
      value.insert = value.insert.hollow
    }
  })
  let quillToHtml = new QuillDeltaToHtmlConverter(queryDetal, {})
  let htmlString = quillToHtml.convert()
  // 创建DOM解析器
  const parser = new DOMParser()
  // 解析HTML字符串
  const doc = parser.parseFromString(htmlString, 'text/html')
  // 获取所有类名为ql-formula的span标签
  const spans = doc.querySelectorAll('span.ql-formula')

  // const spans = doc.querySelectorAll('span.ql-formula');
  // 遍历所有span标签并替换内容
  spans.forEach((span) => {
    let katexHtml = katex.renderToString(span.innerText, {
      throwOnError: false
    })
    const regex = /<span class="katex-mathml">([\s\S]*?)<\/span>/
    const match = regex.exec(katexHtml)
    let targetHtmlString = match ? match[0] : span.innerText
    span.innerHTML = targetHtmlString
  })
  return doc.body.innerHTML
}
/**
 * 获取编译器html内容且存在挖空功能
 * @param {*} queryDetal：富文本内容
 * 父级要绑定点击事件： addEventHtmlClick
 * @returns
 */
export const getQuillContentHollowingOut = (queryDetal, option = {}) => {
  let values = JSON.parse(queryDetal)
  values.forEach((value) => {
    if (value?.insert?.divider?.trim() === '') {
      value.insert = ' '
      value.attributes = {
        divider: ''
      }
    }
  })
  const converterOptions = {
    customTagAttributes: (format, deltaOp) => {
      if (format.insert.type == 'image') {
        let attributes = format.attributes
        if (format.attributes.style) {
          let formatInfo = JSON.parse(format.attributes.style).formatInfo
          attributes['formatInfo'] = JSON.stringify(formatInfo).replace(/"/g, "'") // 替换双引号为单引号
          attributes['canClick'] = option?.canClick || false
          delete attributes.style
        }
        return attributes
      } else if (format.attributes.divider === '') {
        let attributes = format.attributes
        attributes['class'] = 'ql-customLine'
        return attributes
      }
      return null // 使用默认处理方式
    }
  }
  let converter = new QuillDeltaToHtmlConverter(values, converterOptions)
  converter.renderCustomWith((customOp, contextOp) => {
    if (customOp.insert.type === 'hollow') {
      let val = customOp.insert.value
      if (!val) return
      // 如果是文本
      let defaultColor = ''
      let newstyle = []
      for (let key in customOp.attributes) {
        if (customOp.attributes.hasOwnProperty(key) && key !== 'isHollow' && key !== 'background') {
          newstyle.push(key + ':' + customOp.attributes[key] + '')
          if (key == 'color') {
            defaultColor = customOp.attributes[key]
          }
        }
      }
      if (typeof val == 'string') {
        return `<span isHollow='true' defaultColor='${defaultColor}' style='${newstyle.join(
          '; '
        )}'>${val}</span>`
      } else if (val.image && typeof val.image == 'string') {
        return `<span isHollow='true' defaultColor='${defaultColor}' style='${newstyle.join(
          '; '
        )}'><img src="${val.image}" /></span>`
      } else if (val.formula && typeof val.formula == 'string') {
        // 如果是公式
        // 如果操作是插入公式的操作，将文本添加到 plainText 中
        let katexHtml = katex.renderToString(val.formula, {
          throwOnError: false
        })
        // 定义一个正则表达式来匹配 <span class="katex-mathml">...</span> 标签
        const regex = /<span class="katex-mathml">([\s\S]*?)<\/span>/
        const match = regex.exec(katexHtml)
        // 提取到的 HTML 字符串
        let targetHtmlString = match ? match[0] : val.formula
        return `<span isHollow='true' defaultColor='${defaultColor}' style='${newstyle.join(
          '; '
        )}'><span class="ql-formula" style='display:flex;align-items: self-start;'>${targetHtmlString}</span> </span>`
      }
    }
  })
  let htmlString = converter.convert()
  // 创建DOM解析器
  const parser = new DOMParser()
  // 解析HTML字符串
  const doc = parser.parseFromString(htmlString, 'text/html')
  // 获取所有类名为ql-formula的span标签
  const spans = doc.querySelectorAll('span')
  // const spans = doc.querySelectorAll('span.ql-formula');
  // 遍历所有span标签并替换内容
  spans.forEach((span) => {
    // 如果是公式就进行处理
    if (span.classList.contains('ql-formula')) {
      let katexHtml = katex.renderToString(span.innerText, {
        throwOnError: false
      })
      const regex = /<span class="katex-mathml">([\s\S]*?)<\/span>/
      const match = regex.exec(katexHtml)
      let targetHtmlString = match ? match[0] : ''
      span.innerHTML = targetHtmlString
    } else if (span.attributes && span.attributes.isHollow) {
      span.style.cursor = 'pointer' // 鼠标指针
      span.classList.add('hollowingUut-class') // 添加挖空显示的样式
    }
  })
  return doc.body.innerHTML
}
// 防抖
export const debounce = (func, delay) => {
  let timer
  let immediate = true // 用于标记是否立即执行
  const debounced = function () {
    const context = this
    const args = arguments
    if (immediate) {
      func.apply(context, args)
      immediate = false
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      immediate = true
    }, delay)
  }
  // 立即执行
  debounced()
  return debounced
}
//数字格式化
export function numberToStr(num) {
  if (num >= 10000) {
    return Math.floor((num / 10000) * 10) / 10 + 'w'
  } else if (num >= 1000) {
    return Math.floor((num / 1000) * 10) / 10 + 'k'
  } else {
    return num.toString()
  }
}
// 生成唯一key
export function getRandomCode(length) {
  if (length > 0) {
    var data = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z'
    ]
    var nums = ''
    for (var i = 0; i < length; i++) {
      let aa = Math.random() * 61 + ''
      var r = parseInt(aa)
      nums += data[r]
    }
    return nums
  } else {
    return false
  }
}
//文件类型图标
export const fileImgList = {
  c0: {
    img: new URL('@/assets/image/fileImage/card.png', import.meta.url).href,
    name: '双面卡'
  },
  c2: {
    name: '图片卡',
    img: new URL('@/assets/image/fileImage/photo.png', import.meta.url).href
  },
  c4: {
    name: '文档卡',
    img: new URL('@/assets/image/fileImage/word.png', import.meta.url).href
  },
  c5: {
    name: '多维卡',
    img: new URL('@/assets/image/fileImage/multiDimCard.png', import.meta.url).href
  },
  c6: {
    name: '习题卡',
    img: new URL('@/assets/image/fileImage/topicCase.png', import.meta.url).href
  },
  c7: {
    name: '音频卡',
    img: new URL('@/assets/image/fileImage/audioCard.png', import.meta.url).href
  },
  c8: {
    name: '视频卡',
    img: new URL('@/assets/image/fileImage/videoCard.png', import.meta.url).href
  },
  c9: {
    name: '文章卡',
    img: new URL('@/assets/image/fileImage/article.png', import.meta.url).href
  },
  f0: {
    img: new URL('@/assets/image/fileImage/folder.png', import.meta.url).href,
    name: '文件'
  },
  f1: {
    img: new URL('@/assets/image/fileImage/mindMap.png', import.meta.url).href,
    name: '思维导图'
  },
  c12: {
    img: new URL('@/assets/image/fileImage/directory.png', import.meta.url).href,
    name: '目录大纲'
  },
  f3: {
    img: new URL('@/assets/image/fileImage/directory.png', import.meta.url).href,
    name: '目录大纲'
  },
  f4: {
    img: new URL('@/assets/image/fileImage/block.png', import.meta.url).href,
    name: '便签卡'
  }
}

//处理思维导图数据list->tree
export const preProcessMindMapData = (list) => {
  let mindMapData = JSON.parse(JSON.stringify(list))
  let mapType = {
    c0: { name: '卡盒', id: 'c_', type: 'card' },
    f1: { name: '单项导图', id: 'f_', type: 'file' },
    f0: { name: '文件夹', id: 'f_', type: 'file' }
  }
  let nlist = []
  mindMapData.forEach((val) => {
    let pid = val.parentId || val.rootId
    // 排除不符合的类型
    if ((!val.fileType) in mapType) return
    let obj = {
      id: mapType[val.fileType].id + val.id,
      label: val.title || '',
      keyname: mapType[val.fileType].type,
      parentId: 'f_' + pid
    }
    // 双面卡盒需要添加额外数据
    if (obj.keyname == 'card') {
      obj['labelTwo'] = '学习卡' + val.cardNum
    }
    nlist.push(obj)
  })
  const map = {}
  const tree = []
  // 第一步：遍历 list 并填充 map
  nlist.forEach((item) => {
    if (!item.children) {
      item.children = []
    }
    map[item.id] = item
  })
  // 第二步：构建树状结构
  nlist.forEach((item) => {
    if (item.parentId && map[item.parentId]) {
      map[item.parentId].children.push(item)
    } else {
      tree.push(item)
    }
  })
  return tree
}
// 判断当前设备是否为移动设备
export function detectDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  // 检测是否为移动设备
  const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)
  if (isMobile) {
    return true
  } else {
    return false
  }
}
// 判断是否为微信浏览器
export function isWeixin() {
  let ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('micromessenger') !== -1
}
